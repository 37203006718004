import { Injectable, Inject } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthConfig } from './auth.config';

@Injectable()
export class AuthService {
  private authenticated = false;

  constructor(private config: AuthConfig, private http: Http) {}

  public login(email: string, password: string): Observable<boolean> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.http
      .post(
        `${this.config.baseUrl}/auth/login`,
        { email: email, password: password },
        { headers }
      )
      .pipe(
        map((data: Response) => data.json()),
        map((result: any) => {
          if (result.status === 'ok') {
            localStorage.setItem('token', result.token);

            return true;
          }
          return false;
        }, catchError(this.handleError))
      );
  }

  public resetPassword(email: string): Observable<boolean> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.http
      .post(
        `${this.config.baseUrl}/auth/recovery`,
        { email: email },
        { headers }
      )
      .pipe(
        map((data: Response) => data.json()),
        map((result: any) => {
          return result.status === 'ok';
        }),
        catchError(this.handleError)
      );
  }

  public logout(): Observable<boolean> {
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${this.getToken()}`);

    return this.http
      .post(`${this.config.baseUrl}/auth/logout`, '', {
        headers
      })
      .pipe(
        map((data: Response) => data.json()),
        map((result: any) => {
          if (result.success) {
            localStorage.removeItem('token');

            return true;
          }

          return false;
        }),
        catchError(this.handleError)
      );
  }

  public isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  private handleError(error: any): Observable<never> {
    return throwError(error);
  }
}
