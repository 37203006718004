import { NgModule, ModuleWithProviders } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthConfig } from './auth.config';

@NgModule({
  imports: [],
  declarations: [],
  exports: []
})
export class AuthModule {
  static forRoot(config: AuthConfig): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {
          provide: AuthConfig,
          useValue: config
        }
      ]
    };
  }
}
